@font-face {
    font-family: "icons";
    src: url("./icons.eot?022e51a9b7fefbe27363b7f5464584fa?#iefix") format("embedded-opentype"),
    url("./icons.svg?022e51a9b7fefbe27363b7f5464584fa#icons") format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-lock:before {
    content: "\f101";
}
