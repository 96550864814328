/* Generated by Font Squirrel (https://www.fontsquirrel.com) on July 12, 2016 */



@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('museosanscyrl_0-webfont.eot');
    src: url('museosanscyrl_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('museosanscyrl_0-webfont.svg#museo_sans_cyrl300') format('svg');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('museosanscyrl_1-webfont.eot');
    src: url('museosanscyrl_1-webfont.eot?#iefix') format('embedded-opentype'),
         url('museosanscyrl_1-webfont.svg#museo_sans_cyrl500') format('svg');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('museosanscyrl_2-webfont.eot');
    src: url('museosanscyrl_2-webfont.eot?#iefix') format('embedded-opentype'),
         url('museosanscyrl_2-webfont.svg#museo_sans_cyrl700') format('svg');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('museosanscyrl_3-webfont.eot');
    src: url('museosanscyrl_3-webfont.eot?#iefix') format('embedded-opentype'),
         url('museosanscyrl_3-webfont.svg#museo_sans_cyrl900') format('svg');
    font-weight: 900;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('museosanscyrl-webfont.eot');
    src: url('museosanscyrl-webfont.eot?#iefix') format('embedded-opentype'),
         url('museosanscyrl-webfont.svg#museo_sans_cyrl100') format('svg');
    font-weight: 100;
    font-style: normal;

}
