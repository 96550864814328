body,
body * {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-variant: normal;
  font-feature-settings: normal;
  box-sizing: border-box;
}

body {
  overscroll-behavior-y: none;
}

body a {
  text-decoration: none;
}

body a,
body button {
  cursor: pointer;
}

.ps.scrollbar-container {
  position: relative;
}

.featurefm__main {
}